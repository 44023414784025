import React, { Component } from 'react';
// import ReactGA from 'react-ga';
// import $ from 'jquery';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import About from './components/About';
import Nav from './components/nav'
// import Resume from './Components/Resume';
// import Contact from './Components/Contact';
// import Portfolio from './Components/Portfolio';
import Blog from './components/Blog'
import { BrowserRouter , Switch, Route } from "react-router-dom";
import resumeData from './data'

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      foo: 'bar',
      resumeData: {}
    };

    // ReactGA.initialize('UA-110570651-1');
    // ReactGA.pageview(window.location.pathname);

  }

  // getResumeData(){
  //   $.ajax({
  //     url:'./resumeData.json',
  //     dataType:'json',
  //     cache: false,
  //     success: function(data){
  //       this.setState({resumeData: data});
  //     }.bind(this),
  //     error: function(xhr, status, err){
  //       console.log(err);
  //       alert(err);
  //     }
  //   });
  // }

  // componentDidMount(){
  //   this.getResumeData();
  // }

  render() {
    return (
      <BrowserRouter>
      <div>
        <Nav />
        <Switch>
          <Route  path="/"  exact={true}  render={() => (
              <div><Header data={resumeData.main}/>
               <div className="App" dir="rtl">
         </div>
              </div>
            )}
          />
          <Route  path="/blog" render={() => (<Blog />)}/>
          <Route  path="/about" render={() => ( <About data={resumeData.main}/>)}/>
        </Switch>
         <Footer data={resumeData.main}/>
      </div>
    </BrowserRouter>
    );
  }
}

export default App;
