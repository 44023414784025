import React from 'react'
import Login from 'dugoly-login'



export default ()=>{
    return( <nav id="nav-wrap">

    <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
     <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

    <ul id="nav" className="nav">
      <Login style={{height:"50px"}}/> 
    
<div>
       <li><a className="smoothscroll" href="../">דף הבית</a></li>
       <li><a className="smoothscroll" href="/about">אודות</a></li>
       <li><a className="smoothscroll" href="/blog">בלוג</a></li>
        {/* <li><a className="smoothscroll" href="#resume">קורות חיים</a></li> */}
       {/* <li><a className="smoothscroll" href="#portfolio">עבודות</a></li> */}
       {/* <li><a className="smoothscroll" href="/contact">צור קשר</a></li> */}
    </div></ul>
   
 </nav> )
}