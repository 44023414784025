export default {
    "main": {
      "name":"צייד האוצרות",
      "description":"את החוויה המרגשת הבאה שלכם, תעשו איתנו ..ערבי כיתה..אירועים משפחתיים..חפש את המטמון..בעקבות האוצר האבוד..ציד אוצרות..פרסים והפתעות..חוויה ואתגר..גיבוש חברתי..ימי הולדת..חידות וחידונים..חדרי בריחה וקופסאות פיצוח..ועוד",
      "image":"profilepic.jpg",
      "bio":"מאז ילדותי אהבתי משחקי אתגר וחדרי בריחה, את האהבה הזאת הפכתי למקצוע, וכבר יותר מ 8 שנים שאנחנו מפתחים, יוצרים ומאתגרים בחוויות שמעבר לדימיון.",
      "contactmessage":"חדרי בריחה, אתגרי מחשבה, חידונים והדמיות, לא תוכלו לשער על מה מדובר.",
      "email": "treasure@motto.co.il",
      "phone": "0524635325",
      "github":"https://github.com/nordicgiant2/react-nice-resume",
      "project":"https://github.com/nordicgiant2/react-nice-resume",
      "address":{
        "street":"הממציא, הר חומה",
        "city":"ירושלים",
        "state":"",
        "zip": ""
      },
      "website": "",
      "resumedownload":"",
      "social":[
        {
          "name":"facebook",
          "url":"",
          "className":"fa fa-facebook"
        },
        {
          "name":"twitter",
          "url":"http://twitter.com",
          "className":"fa fa-twitter"
        },
        {
          "name":"linkedin",
          "url":"",
          "className":"fa fa-linkedin"
        },
        {
          "name":"instagram",
          "url":"",
          "className":"fa fa-instagram"
        },
        {
          "name":"github",
          "url":"",
          "className":"fa fa-github"
        }
      ]
    },
    "resume":{
      "skillmessage":"Here you can create a short write-up of your skills to show off to employers",
      "education":[
        {
          "school":"University?",
          "degree":"Masters in Beer tasting",
          "graduated":"April 2007",
          "description":"Describe your experience at school, what you learned, what useful skills you have acquired etc."
        },
        {
          "school":"School #1 Maybe College?",
          "degree":"What did you study 101",
          "graduated":"March 2003",
          "description":"Describe your experience at school, what you learned, what useful skills you have acquired etc."
        }
      ],
      "work":[
        {
          "company":"Awesome Design Studio",
          "title":"Senior UX God ",
          "years":"March 2010 - Present",
          "description":"Describe work, special projects, notable achievements, what technologies you have been working with, and anything else that would be useful for an employer to know."
        },
        {
          "company":"Super Cool Studio",
          "title":"Junior bug fixer",
          "years":"March 2007 - February 2010",
          "description":"Describe work, special projects, notable achievements, what technologies you have been working with, and anything else that would be useful for an employer to know."
        }
      ],
      "skills":[
        {
          "name":"Python",
          "level":"60%"
        },
        {
          "name":"VueJs",
          "level":"55%"
        },
        {
          "name":"ReactJs",
          "level":"50%"
        },
        {
          "name":"CSS",
          "level":"90%"
        },
        {
          "name":"PHP",
          "level":"80%"
        },
        {
          "name":"Swift",
          "level":"50%"
        },
        {
          "name":"JAVA",
          "level":"80%"
        }
      ]
    },
    "portfolio":{
      "projects": [
        {
          "title":"Canadian Wanderlust",
          "category":"My Travel Blog for my post-university travels",
          "image":"canadian-wanderlust.jpg",
          "url":"https://www.canadianwanderlust.com"
        },
        {
          "title":"Fury Fighting Gear",
          "category":"(offline now) A fighting gear company I started",
          "image":"fury-fighting-gear.jpg",
          "url":"http://www.timbakerdev.com"
        },
        {
          "title":"Original Thai Food",
          "category":"Website I built for a restaurant I like in Thailand",
          "image":"original-thai-food.jpg",
          "url":"http://www.timbakerdev.com/originalthaifood.github.io"
        },
        {
          "title":"Resume Website",
          "category":"A React based resume website template",
          "image":"resume-website.jpg",
          "url":"http://www.timbakerdev.com"
        },
        {
          "title":"Smirkspace",
          "category":"(MVP Only) A React and Meteor based chat University project.",
          "image":"smirkspace.jpg",
          "url":"http://www.smirkspace.com"
        }
      ]
    }
  }
  