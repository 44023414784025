import React from 'react'
import Blog from 'dugoly-blog'
import Ads from 'dugoly-shared-advertising'
const blog=()=>{

    return( <div >
        <br /><br /><br />
        <Ads 
      imgPath="http://www.easydone.online/uploads/-M_PtQR42NCr_cSbLWgb/6b052fa6-9861-4ec7-8cf6-2c58378ca84a.gif"
link="https://motto.co.il/Promotion" 
language="he" 
resize={true} />
        <Blog ownerUid="-Ma8lcRv6SCBIc1H9NLZ" bCode="-Ma8mWzILofXQL7ym4BN" BlogTitle="בלוג הסתרים של צייד האוצרות" dir="rtl"  withRouter = {{RelativePath: "/blog"}}/>
    </div>)
}

export default blog